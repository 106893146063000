import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Link from '/src/components/LocalizedLink';
// import ForthcomingCourses from '../../components/ForthcomingCourses';
// import AplicationProcess from '../../components/AplicationProcess';
import ReviewsSection from '../../components/ReviewsSection';
import Faq from '../../components/Faq';
import InternalSectionWithImage from '../../components/InternalSectionWithImage';

import { URL_KEYS_ROUTES_EN, URL_ROUTES_EN } from '../../constants/routes';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import iconTick from '../../assets/images/svg/tick.svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import imgBenefit1 from '../../assets/images/online-trinity-course/Internationally Recognized Qualification.svg';
import imgBenefit2 from '../../assets/images/online-trinity-course/100% Online.svg';
import imgBenefit3 from '../../assets/images/online-trinity-course/Small Group Sizes .svg';
import imgBenefit5 from '../../assets/images/online-trinity-course/Post-Grad Job Prospects 2.svg';
import imgReview from '../../assets/images/intensive-spanish/Chris Tagle.jpg';

import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import iconCalendar from '../../assets/images/svg/calendar.svg';
import iconClock from '../../assets/images/svg/clock.svg';
// import iconColumn from '../../assets/images/trinity-cert-tesol-course-page/column.svg';
import iconAccomodation from '../../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../../assets/images/student-support/Bureaucracy.svg';
import imgBigImage from '../../assets/images/intensive-spanish/Extensive_spanish_exciting.jpeg';
import imgDevelopingTeacherCourse from '../../assets/images/other-popular-courses/Developing Teacher Course.png';
import imgTeachYoungLearners from '../../assets/images/other-popular-courses/Teacher Young Learners (1).png';

import '../../assets/styles/pages/extensive-spanish-page.scss';

import CourseSchedule from '../../components/CourseSchedule';

const ExtensiveSpanish = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  return (
    <Layout
      title={'Part-Time Spanish Classes in Barcelona | 4h / Week Course'}
      description={
        'Take your Spanish classes in the morning before work! Study for 4 hours per week in central Barcelona and start the journey towards Spanish fluency.'
      }
    >
      <div className="extensive-spanish-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14">4 hours/week</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14">Morning Timetable</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="calendar" />
                    <span className="c-text-14">3–12 months</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">Part-Time Spanish</h1>
                <p className="c-first-section__description">
                  Study regularly for 4 hours per week to see long-term improvements.
                </p>
                <div className="c-first-section__btns">
                  <Link to={URL_ROUTES_EN.APPLY} className="c-btn c-btn--red">
                    Sign up now
                    <IconArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Squeeze Spanish Into Your Busy Schedule
                </h2>
                <p className="c-text-18 description second-section__l">
                  Experience the same excellent content found in our intensive and semi-intensive
                  programs, tailored to fit your lifestyle. These classes are ideal if you work full
                  time or have other commitments in Barcelona, but are keen to see progress in your
                  Spanish. The classes are twice a week, in the morning, allowing you plenty of time
                  to work, study or practise Spanish during the day.
                </p>
                {/* <p className="notice c-text-18">
                  Eligible for government grants under Covid retraining schemes.
                </p> */}
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You have a busy work schedule and want to improve your Spanish.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want free time during the day for other commitments.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy regular, consistent study.</p>
                  </div>
                </div>
                {/* <Link to="/guide-to-teaching-english-online/" className="c-btn c-btn--red mt-20">
                  Get your free online teaching guide
                  <IconArrowRight />
                </Link> */}
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="imgBenefit1" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Small Groups</h3>
                      <p className="c-text-14">
                        We strictly limit the groups to 8, giving you maximum time with the teacher
                        and plenty of time to practise talking.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="imgBenefit2" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Excellent value</h3>
                      <p className="c-text-14">
                        Learning Spanish doesn’t have to be expensive. We believe in accessible
                        education for all, and make our courses as affordable as possible.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="imgBenefit3" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Focus on Speaking</h3>
                      <p className="c-text-14">
                        Our teachers understand that you want to speak and express yourself in
                        Spanish, and we put a big emphasis on this in every class.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="imgBenefit5" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Fun and varied classes</h3>
                      <p className="c-text-14">
                        Build your vocabulary and fluency in no time, with fun and engaging classes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="Chris Tagle" className="img-main" />
                    <img src={imgDots} alt="dots" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="quotes" />
                    <p>
                      My experience studying Spanish at ILA surpassed all expectations. The teachers
                      were so knowledgeable and helpful and Mirna was always so friendly and quick
                      to reply. I particularly appreciated the focus on practical speaking skills
                      and now I feel much more confident communicating in Spanish.
                      <br />
                      <br />
                      Without a doubt, this course has greatly enhanced my life in Barcelona and I’m
                      so grateful for what I’ve learned.
                      <br />
                      <strong>Chris Tagle</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        4 hours per week of classes with a qualified teacher.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">All necessary course & learning material. </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">A dedicated course coordinator</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to our beautiful library, high-speed wifi, computers and learning
                        resources.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to our cultural programme (activities are paid separately)
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Expert guidance on student visas, if required.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Social calendar at the school</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Attendance certificate</p>
                    </div>
                    {/* <h4>BONUS</h4>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        2-day classroom teaching workshop in Barcelona (post pandemic)
                      </p>
                    </div> */}
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">Full Price:</h3>
                    <h3 className="c-title-32">
                      360€ <span className="lowercase">/3 month</span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      DISCOUNT PRICE:{' '}
                      <span className="notion">
                        See our{' '}
                        <FormattedMessage id={URL_KEYS_ROUTES_EN.COURSES_DATES}>
                          {(locale) => (
                            <Link to={locale[0]} className="colored no-link">
                              pricing page
                            </Link>
                          )}
                        </FormattedMessage>{' '}
                        for more course options.
                      </span>
                    </h3>
                    <h3 className="c-title-32">
                      {' '}
                      900€ <span className="lowercase">for 9 months</span>
                    </h3>
                  </div>
                  <div className="block_r__btns">
                    <Link to={URL_ROUTES_EN.APPLY} className="c-btn c-btn--red">
                      Book Now
                      <IconArrowRight />
                    </Link>
                    <a
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://form.jotform.com/231496158857369'}
                    >
                      Test Your Spanish
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="internal-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    What will the 100% Online Trinity CertTESOL{' '}
                    <span className="colored no-link">be like?</span>
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    Our 100% Online Trinity CertTESOL course is done via Zoom and does not require
                    any in-person training at all. Your workshops, lesson preparation, teaching
                    practice and assignments will all be carried out online.
                  </p>
                  <p className="c-text-16 block__description">
                    The Trinity CertTESOL course will take part over 12 weeks, with 10 hours per
                    week of live tutor time, plus 5 hours per week of self study and assignments.
                    See below for more detailed information on the timetable.
                  </p>
                  <p className="c-text-16 block__description">
                    The timetable has been designed to cater for people who have full time work or
                    study, and would like to combine their teacher training with other commitments.
                  </p>
                  <p className="c-text-16 block__description">
                    The course timetable has been carefully chosen to satisfy students in as many
                    time zones as possible and we may open up additional sessions at different times
                    to cater to even more students.
                  </p>
                  <p className="c-text-16 block__description">
                    The course is Monday - Friday and you will take part in live training sessions
                    every day, with the opportunity to spend time in smaller groups as you would in
                    a real classroom. You’ll also get plenty of time for self-study and lesson
                    preparation, with a live tutor and group support at the click of a button.
                    You’ll teach lessons online to real groups of students and receive detailed
                    feedback from your course tutor.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgPhoto1} alt="Photo1" className="block_r__img" />
                  </div>
                  <div className=" block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">
                  <FormattedMessage id="onlineCerttesol.sec8.title" />
                </h3>
                <p className="c-text-18">

                  <FormattedMessage
                    id="onlineCerttesol.sec8.description"
                    values={{
                      accent: (chunk) => <span className="text-with-margin">{chunk}</span>,
                      accent2: (chunk) => <span className="text-with-margin">{chunk}</span>,
                    }}
                  />
                  <ul className="timetable-list">
                    <li className="timetable-list__item">
                       6pm - 9pm (EST - suitable for North Americans)
                      <FormattedMessage id="onlineCerttesol.sec8.notification.time1" />
                    </li>
                    <li className="timetable-list__item">
                       6pm - 9pm (GMT - suitable for Europeans)
                      <FormattedMessage id="onlineCerttesol.sec8.notification.time2" />
                    </li>
                  </ul>
                </p>

              </div>
              <div className="col-lg-6 col-12">
                <Faq title="Unit 1 - Teaching Skills" description={week1} />
                <Faq title="Unit 2 - Language Awareness and Skills" description={week2} />
                <Faq title="Unit 3 - Learner Profile " description={week3} />
                <Faq title="Unit 4 - Materials Assignment" description={week4} />
                <Faq title="Unit 5 - Unknown Language Journal " description={week5} />
                <Faq title="Assesment Criteria" description={assesmentCriteria} />
                <a
                  target="_blank"
                  href={fileTrinityCertTESOLSyllabus}
                  className="c-btn c-btn--red mt-50"
                >
                  <FormattedMessage id="btn.downloadTrinitySyllabus" />
                </a>
              </div>
            </div>
          </div>
        </section> */}

        <section className="fourth-section courses-section c-section-py-70">
          <div className="container">
            <h1 className="courses-section__title c-title-34">Course Dates & Timetable</h1>
            {/* <p className="courses-section__description c-title-24">
              Courses Every Monday (I’d like to publish individual dates)
              <br />
              <br />
              <b>Timetable:</b>
            </p>
            <ul className="courses-section__list">
              <li className="courses-section__list-item c-text-18">
                Mondays & Wednesday, 09.30-11.30 / 11.30-13.30
              </li>
              <li className="courses-section__list-item c-text-18">
                Tuesdays & Thursdays, 09.30-11.30 / 11.30-13.30
              </li>
              <li className="courses-section__list-item c-text-18">
                Fridays, 09.30-13.30 (1 class per week)
              </li>
            </ul> */}

            <CourseSchedule
              levels={() => (
                <ul>
                  <li>
                    <b>A1 - C1</b>
                  </li>
                </ul>
              )}
              startDates="Every Monday"
              schedule={() => (
                <ul>
                  <li>
                    <b>Mondays & Wednesday, 09.30-11.30 / 11.30-13.30 / 18.00-20.00</b>
                  </li>
                  <li>
                    <b>Tuesdays & Thursdays, 09.30-11.30 / 11.30-13.30 / 18.00-20.00</b>
                  </li>
                  <li>
                    <b>Online Option: Mondays & Wednesdays, 19.00-21.00</b>
                  </li>
                </ul>
              )}
            />
            <div className="col text-center mt-5">
              <Link to={URL_ROUTES_EN.APPLY} className="c-btn c-btn--red">
                Book now
                <IconArrowRight />
              </Link>
            </div>

            {/* <ForthcomingCourses
              cardData={allPrismicCourses.slice(0, isMore ? allPrismicCourses.length : 4)}
              title={{ id: 'forthcomingCourses.title.upcoming' }}
            />
            {allPrismicCourses.length > 4 ? (
              <button
                onClick={() => setIsMore(!isMore)}
                className="c-btn c-btn--red-border load-more-dates"
              >
                {isMore ? 'Show Less Dates' : 'Load More Dates'}
                <IconArrowRightRed />
              </button>
            ) : null} */}
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            {/* <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p> */}
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.CULTURAL_ACTIVITIES}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section c-section-py">
          <InternalSectionWithImage
            imgSrc={imgBigImage}
            title="Learn Spanish in Spain’s most exciting city"
          />
          {/* <AplicationProcess isOnlineCourse /> */}
        </section>
        {/*
        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="trinityCerttesol.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="sform.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section> */}

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={'Check out our latest reviews'}
            title={
              'We’ve Helped Hundreds of Students Learn Spanish and Set Up A Life In Barcelona. Here’s What They Have To Say:'
            }
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              {/* <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title="When does the course start?"
                  description="We start new courses Every Monday."
                />
                <Faq
                  title="What is the course timetable?"
                  description="09.30 - 11.30 or 11.30-13.30, Monday to Friday."
                />
                <Faq
                  title="What happens if the level of the group is too hard / easy for me?"
                  description="If you find that the level of the group is too hard for you, don't worry! Our instructors are experienced in accommodating different levels of learners. They will provide additional support, explanations, and resources to help you catch up and progress at your own pace. If you prefer you can also move down a level. <br/> <br/> If you feel that the level is too easy for you, you can discuss with your instructor to explore options for moving up to a more challenging group."
                />
                <Faq
                  title="Who are my classmates? "
                  description="Your classmates may come from different backgrounds, countries, and age groups, creating a diverse learning environment. Interacting with your classmates will provide you with opportunities to practise your Spanish skills and learn from each other's experiences."
                />
                <Faq
                  title="How quickly can I move up a level?"
                  description="The pace at which you can move up a level will depend on your individual progress and the course structure. Our instructors will assess your language skills regularly and provide feedback on your progress."
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title="Can I get a certificate of completion?"
                  description="Yes, upon successfully completing the Spanish course, you will be eligible to receive a certificate of completion. The certificate will verify your participation in the course and indicate the level of proficiency you have achieved. It can be a valuable addition to your resume or used to showcase your language skills to potential employers or educational institutions. Please note that specific criteria, such as attendance and performance, may need to be met to qualify for the certificate."
                />
                {/* <Faq
                  title="Do I need a visa?"
                  description="If you are a European Union citizen then you are free to work and study in any of the EU countries. If you are a non-EU citizen then you can stay up to three months as a tourist and you are free to study and do private classes during this time. If you would like to stay longer and obtain a work permit, the easiest way to do this is with a student visa, which will allow you to work up to 20 hours per week (which is full time for an English teacher). TEFL Iberia can process a student visa for you, for more detailed information please contact classes@ilabarcelona.com."
                />
                <Faq
                  title="I can’t attend because I’m in a different timezone. Will there be different timetables?"
                  description="Yes, we can open up new groups with different timetables based on demand."
                />
                <Faq
                  title="What is the average cost of living in Spain?"
                  description="In Barcelona you can expect to pay 550-600€ for a quality double room in a shared flat in the city centre, then about 150€/week on food and entertainment – this money will stretch quite far as Barcelona can be very cheap compared to the UK / US / Canada / Australia. <br/> <br/> Seville is even cheaper: a double room in a shared flat will cost about 350€ and you can expect to spend about 120€/week on food and entertainment."
                /> */}
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage
                id="trinityCerttesol.sec11.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            {/* <p className="c-text-18">
              <FormattedMessage id="trinityCerttesol.sec11.description" />
            </p> */}
            <div className="row">
              <div className="col-sm-6 col-lg-6">
                <FormattedMessage id={URL_KEYS_ROUTES_EN.INTENSIVE_SPANISH}>
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgDevelopingTeacherCourse}
                        alt="Developing Teacher Course"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">20 hours </span>
                        /week
                        <br />
                        <span className="item__price">580€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Intensive Spanish</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-6">
                <FormattedMessage id={URL_KEYS_ROUTES_EN.SEMI_INTENSIVE_SPANISH}>
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTeachYoungLearners}
                        alt="Teaching Young Learners"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">10 hours </span>
                        /week <br />
                        <span className="item__price">320€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Semi-Intensive Spanish</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "online-trinity-course" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default ExtensiveSpanish;
